import { Adb } from "../adb";
import { escapeArg } from "./shell";


export async function install(
    adb: Adb,
    apk: ArrayLike<number> | ArrayBufferLike | AsyncIterable<ArrayBuffer>,
    onProgress?: (uploaded: number) => void,
): Promise<void> {
    const filename = `/data/local/tmp/${Math.random().toString().substr(2)}.apk`;

    // Upload apk file to tmp folder
    const sync = await adb.sync();
    await sync.write(filename, apk, undefined, undefined, onProgress);
    sync.dispose();

    // Invoke `pm install` to install it
    await adb.exec('pm', 'install', escapeArg(filename));

    // Remove the temp file
    await adb.rm(filename);
}

export async function uninstall(
    adb: Adb,
    packageName: string
): Promise<void> {
    // Uninstall package
    await adb.exec('pm', 'uninstall', escapeArg(packageName));
}

export async function installVBConfig(
    adb: Adb, 
    configFile: ArrayLike<number> | ArrayBufferLike | AsyncIterable<ArrayBuffer>,
    onProgress?: (uploaded: number) => void,
) : Promise<void> {
    const tmpFilePrefix = `/data/local/tmp/${Math.random().toString().substr(2)}`;
    const filename = `${tmpFilePrefix}.gz`;

    // Upload apk file to tmp folder
    const sync = await adb.sync();
    await sync.write(filename, configFile, undefined, undefined, onProgress);
    sync.dispose();

    
    // Move config file
    await adb.exec(`mv  ${escapeArg(filename)} /sdcard/lvl.config`);

    await adb.exec('reboot');
}

export async function installVB(
    adb: Adb,
    visionBuddyUpdateFile: ArrayLike<number> | ArrayBufferLike | AsyncIterable<ArrayBuffer>,
    onProgress?: (uploaded: number) => void,
): Promise<void> {
    const tmpFilePrefix = `/data/local/tmp/${Math.random().toString().substr(2)}`;
    const filename = `${tmpFilePrefix}.gz`;

    // Upload apk file to tmp folder
    const sync = await adb.sync();
    await sync.write(filename, visionBuddyUpdateFile, undefined, undefined, onProgress);
    sync.dispose();

    
    // Unzip
    await adb.exec(`tar -zxvf  ${escapeArg(filename)} -C /data/local/tmp`);

    // Install Google TTS
    await adb.exec('pm', 'install', escapeArg('/data/local/tmp/resources/texttospeech.apk'));

    // Install VB file
    await adb.exec('pm', 'install', escapeArg('/data/local/tmp/resources/vb.apk'));

    
    await adb.exec('mv /data/local/tmp/resources/lvl.config /sdcard');
    await adb.exec('mkdir -p /sdcard/bootanimation');
    await adb.exec('mv /data/local/tmp/resources/SystemKeyConfig.prop /data/local/tmp');
    await adb.exec('mv /data/local/tmp/resources/bootanimation.zip /sdcard/bootanimation');
    await adb.exec('mv /data/local/tmp/resources/shutdownanimation.zip /sdcard/bootanimation');


    await adb.exec('setprop persist.psensor.sleep.delay -1');
    await adb.exec('setprop persist.psensor.screenoff.delay 65535');
    await adb.exec('setprop persist.pvr.psensor.reset_pose 1');
    await adb.exec('setprop persist.pvr.charge.policy 3');

    
    await adb.exec('setprop persist.pxr.force.home com.vb.vb,com.what.WhatNativeCameraActivity');
    await adb.exec('pm enable com.pvr.launcher');


    await adb.exec('settings put secure tts_default_locale null:,com.google.android.tts:en_US');
    await adb.exec('shell settings put secure tts_default_pitch 100');
    await adb.exec('settings put secure tts_default_rate 100');
    await adb.exec('settings put secure tts_default_synth com.google.android.tts');
    await adb.exec('shell settings put system volume_music_speaker 15');
    await adb.exec('ime disable  com.sohu.inputmethod.sogou.car/.CrossSogouIME');

    await adb.exec('setprop persist.pvrpermission.autogrant 1');
    await adb.exec('setprop persist.pvr.volumeui 0');
    await adb.exec('pm grant com.vb.vb android.permission.CAMERA');
    await adb.exec('pm grant com.vb.vb android.permission.READ_EXTERNAL_STORAGE');
    await adb.exec('pm grant com.vb.vb android.permission.WRITE_EXTERNAL_STORAGE');


    // Remove the temp file
    await adb.rm(filename);
    await adb.exec('rm -rf /data/local/tmp/resources');
    await adb.exec('rm /data/local/tmp/._resources');

    // // Reboot
    await adb.exec('reboot');
}
