import { Callout, DirectionalHint, Link, mergeStyleSets, Text } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import React, { ReactNode, useCallback, useRef } from 'react';
import { ExternalLink } from '../components';
import { withDisplayName } from '../utils';

const classNames = mergeStyleSets({
    callout: {
        padding: '8px 12px',
    },
});

const BoldTextStyles = { root: { fontWeight: '600' } };

interface CopyLinkProps {
    href: string;

    children?: ReactNode;
}

const CopyLink = withDisplayName('CopyLink')(({
    href,
    children,
}: CopyLinkProps) => {
    const calloutTarget = useRef<HTMLButtonElement | null>(null);
    const [calloutVisible, { setTrue: showCallout, setFalse: hideCallout }] = useBoolean(false);
    const copyLink = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        navigator.clipboard.writeText(href);
        calloutTarget.current = e.target as HTMLButtonElement;
        showCallout();
        setTimeout(hideCallout, 3000);
    }, [href]);

    return (
        <>
            <Link onClick={copyLink}>{children || href}</Link>
            <Callout
                directionalHint={DirectionalHint.topCenter}
                hidden={!calloutVisible}
                target={calloutTarget}
                onDismiss={hideCallout}
            >
                <div className={classNames.callout}>
                    Link copied. Open a new tab and paste into address bar.
                </div>
            </Callout>
        </>
    );
});

export const Intro = withDisplayName('Intro')(() => {
    return (
        <>
            <Text block>
                <span>How to upgrade Vision Buddy</span>
            </Text>

            
        </>
    );
});
