import { DefaultButton, MessageBar, ProgressIndicator, Stack, StackItem, Text, Separator } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { pickFile, withDisplayName } from "../utils";
import { chunkFile } from "./file-manager";
import { RouteProps } from "./type";

enum Stage {
    Uploading,

    Uninstalling,

    Installing,

    Completed,

    Configuring
}

interface Progress {
    filename: string;

    stage: Stage;

    uploadedSize: number;

    totalSize: number;

    value: number | undefined;
}

export const Install = withDisplayName('Install')(({
    device,
}: RouteProps): JSX.Element => {
    const [installing, setInstalling] = useState(false);
    const [progress, setProgress] = useState<Progress>();

    const handleConfigOpen = useCallback(async () => {
        const configFile = await pickFile({ accept: '.config' });
        if (!configFile) {
            return;
        }

        setInstalling(true);

        setProgress({
            filename: configFile.name,
            stage: Stage.Installing,
            uploadedSize: 0,
            totalSize: configFile.size,
            value: 0,
        });

        await device!.installVBConfig(chunkFile(configFile), uploaded => {

            if (uploaded !== configFile.size) {
                setProgress({
                    filename: configFile.name,
                    stage: Stage.Uploading,
                    uploadedSize: uploaded,
                    totalSize: configFile.size,
                    value: uploaded / configFile.size * 0.7,
                });
            } else {
                setProgress({
                    filename: configFile.name,
                    stage: Stage.Installing,
                    uploadedSize: uploaded,
                    totalSize: configFile.size,
                    value: 0.7,
                });
            }
           
        })

        setProgress({
            filename: configFile.name,
            stage: Stage.Completed,
            uploadedSize: configFile.size,
            totalSize: configFile.size,
            value: 1,
        });

        alert("Installed Config File");


    }, [device]);

    const handleOpen = useCallback(async () => {
        const file = await pickFile({ accept: '.vbf' });
        if (!file) {
            return;
        }

        setInstalling(true);

        setProgress({
            filename: file.name,
            stage: Stage.Uninstalling,
            uploadedSize: 0,
            totalSize: file.size,
            value: 0,
        });

        await device!.uninstall('com.vb.vb')


        setProgress({
            filename: file.name,
            stage: Stage.Uploading,
            uploadedSize: 0,
            totalSize: file.size,
            value: 0,
        });

        await device!.installVB(chunkFile(file), uploaded => {
            if (uploaded !== file.size) {
                setProgress({
                    filename: file.name,
                    stage: Stage.Uploading,
                    uploadedSize: uploaded,
                    totalSize: file.size,
                    value: uploaded / file.size * 0.7,
                });
            } else {
                setProgress({
                    filename: file.name,
                    stage: Stage.Installing,
                    uploadedSize: uploaded,
                    totalSize: file.size,
                    value: 0.7,
                });
            }
        });

        setProgress({
            filename: file.name,
            stage: Stage.Configuring,
            uploadedSize: file.size,
            totalSize: file.size,
            value: 0.9,
        });

        alert("Installed Vision Buddy!");


        setProgress({
            filename: file.name,
            stage: Stage.Completed,
            uploadedSize: file.size,
            totalSize: file.size,
            value: 1,
        });

        setInstalling(false);
    }, [device]);

    return (
        <>
            <Stack>  
                <StackItem>
                    <MessageBar>
                        <Text>Once the device is connected, please select the Vision Buddy update file on your computer..</Text>
                    </MessageBar>
                </StackItem>
                <Separator />
                <StackItem>
                    <DefaultButton
                        disabled={!device || installing}
                        text="Select Vision Buddy VBF File"
                        onClick={handleOpen}
                    />
                </StackItem>
            </Stack>

            {progress && (
                <ProgressIndicator
                    styles={{ root: { width: 300 } }}
                    label={progress.filename}
                    percentComplete={progress.value}
                    description={Stage[progress.stage]}
                />
            )}

            <Stack>  
                <StackItem>
                    <DefaultButton
                        disabled={!device || installing}
                        text="Select Vision Buddy config File"
                        onClick={handleConfigOpen}
                    />
                </StackItem>
            </Stack>
        </>
    );
});
