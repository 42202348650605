import { Stack, StackItem, DefaultButton, Separator, Toggle } from '@fluentui/react';
import { Adb, AdbDemoModeMobileDataType, AdbDemoModeMobileDataTypes, AdbDemoModeStatusBarMode, AdbDemoModeStatusBarModes, AdbDemoModeWifiSignalStrength } from '@yume-chan/adb';
import React, { useCallback, useEffect, useState } from 'react';
import { withDisplayName } from '../utils';
import { NumberPicker } from './number-picker';

export interface DeviceCommandProps {
    device?: Adb;
    running?: boolean;
    style?: React.CSSProperties;
}

function useDemoModeSetting<T>(
    initialValue: T,
    enabled: boolean,
    setEnabled: (value: boolean) => void,
    onChange: (value: T) => void
): [T, (value: T) => void] {
    const [value, setValue] = useState<T>(initialValue);

    useEffect(() => {
        if (enabled) {
            onChange(value);
        }
    }, [enabled]);

    const handleChange = useCallback((value: T) => {
        setValue(value);
        if (enabled) {
            onChange(value);
        } else {
            setEnabled(true);
        }
    }, [enabled, onChange]);

    return [value, handleChange];
}

function useBooleanDemoModeSetting(
    initialValue: boolean,
    enabled: boolean,
    setEnabled: (value: boolean) => void,
    onChange: (value: boolean) => void
): [boolean, (e: any, value?: boolean) => void] {
    const [value, setValue] = useDemoModeSetting(
        initialValue,
        enabled,
        setEnabled,
        onChange
    );

    const handleChange = useCallback(async (e, value?: boolean) => {
        if (value === undefined) {
            return;
        }
        setValue(value);
    }, [setValue]);

    return [value, handleChange];
}



export const DeviceCommands = withDisplayName('DeviceCommands')(({
    device,
    running,
    style,
}: DeviceCommandProps) => {
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        (async () => {
            setAllowed(false);

            if (device) {
                const allowed = await device.demoMode.getAllowed();
                setAllowed(allowed);
                if (allowed) {
                    setEnabled(await device.demoMode.getEnabled());
                }
            }
        })();
    }, [device]);

    const handleAllowedChange = useCallback(async (e, value?: boolean) => {
        if (value === undefined) {
            return;
        }
        await device!.demoMode.setAllowed(value);
        setAllowed(value);
        setEnabled(false);
    }, [device]);

    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setEnabled(false);
    }, [device]);

    const handleEnabledChange = useCallback(async (e, value?: boolean) => {
        if (value === undefined) {
            return;
        }
        await device!.demoMode.setEnabled(value);
        setEnabled(value);
    }, [device]);

    const showMainAndroidSettings = async () => {
        await device!.exec("am start -a android.settings.SETTINGS");
    }

    const closeSettings = async () => {
        await device!.exec("am force-stop com.android.settings");
    }

    const showPicoSettings = async () => {
        await device!.exec("am start -n com.pvr.shortcut/.MainActivity");
    }

    const closePicoSettings = async () => {
        await device!.exec("am force-stop com.pvr.shortcut");
    }

    const reboot = async () => {
        await device!.exec("reboot");
    }

    const powerOff = async () => {
        await device!.exec("reboot -p");
    }

    return (
        <div style={{ padding: 12, overflow: 'hidden auto', ...style }}>
            <div><strong>Note:</strong></div>
            <div>Vision Buddy Settings Shortcuts</div>
            <Stack>  
                <StackItem>
                    <DefaultButton
                        disabled={!running}
                        text="Main Settings"
                        onClick={showMainAndroidSettings}
                    />
                </StackItem>
                <StackItem>

                    <DefaultButton
                        disabled={!running}
                        text="Close Settings"
                        onClick={closeSettings}
                    />
                </StackItem>
                <Separator />
                <StackItem>
                    <DefaultButton
                        disabled={!running}
                        text="Pico Settings"
                        onClick={showPicoSettings}
                    />
                </StackItem>
                <StackItem>
                    <DefaultButton
                        disabled={!running}
                        text="Close Pico Settings"
                        onClick={closePicoSettings}
                    />
                </StackItem>
                <Separator />
                <StackItem>
                    <DefaultButton
                        disabled={!running}
                        text="Reboot Device"
                        onClick={reboot}
                    />
                </StackItem>
                <StackItem>
                    <DefaultButton
                        disabled={!running}
                        text="Power Off"
                        onClick={powerOff}
                    />
                </StackItem>
            </Stack>

        </div>
    );
});
