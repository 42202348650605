import Dexie, { Table } from 'dexie';

export interface SoftwareVersion {
    id?: number;
    title: string;
    version?: string;
    apk: Blob
}

export class SoftwareDB extends Dexie {
  vbVersions!: Table<SoftwareVersion, number>;
  constructor() {
    super('SoftwareDB');
    this.version(1).stores({
        vbVersions: '++id',
    });
  }

  deleteVersion(vbVersionId: number) {
    return this.transaction('rw', this.vbVersions, () => {
      this.vbVersions.delete(vbVersionId);
    });
  }
}

export const db = new SoftwareDB();

//db.on('downloadDefault', downloadDefault);

export function resetDatabase() {
    return db.transaction('rw', db.vbVersions, async () => {
        await Promise.all(db.tables.map(table => table.clear()));
  });
}