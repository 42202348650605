import { Icon, MessageBar, Separator, TooltipHost, Stack, StackItem, DefaultButton, Text} from '@fluentui/react';
import { AdbFeatures } from '@yume-chan/adb';
import React from 'react';
import { withDisplayName } from '../utils';
import { RouteProps } from './type';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../utils/db";

export const SoftwareVersions = withDisplayName('SoftwareVersions')(({
    device
}: RouteProps): JSX.Element | null => {
    const lists = useLiveQuery(() => db.vbVersions.toArray()) || [];
    const downloadVersion =  async (url: string) => {
        const res = await fetch(url)
        const blob = await res.blob();
        await db.vbVersions.put({
            title: "test file",
            apk: blob
        })
        const updatedList = await db.vbVersions.toArray();
        console.log({ updatedList })
    }
    return (
        <>
            <Stack>  
                <StackItem>
                    <MessageBar>
                        <Text>Once the device is connected, please select the Vision Buddy update file on your computer..</Text>
                    </MessageBar>
                </StackItem>
                <Separator />
                <StackItem>
                    <DefaultButton
                        text="Select Vision Buddy App/File"
                        onClick={() => downloadVersion('https://upload.wikimedia.org/wikipedia/commons/7/77/Delete_key1.jpg')}
                    />
                </StackItem>
            </Stack>

            <span>
                <span>Software Versions: </span>
            </span>
            <Separator />
            {lists?.map(list => (
                <div key={list.id}>list.title</div>
            ))}
        </>
    );
});
