import { IconButton, mergeStyles, mergeStyleSets, Nav, Stack, StackItem } from '@fluentui/react';
import { ThemeProvider, PartialTheme } from '@fluentui/react-theme-provider';
import { initializeIcons } from '@uifabric/icons';
import { Adb } from '@yume-chan/adb';
import React, { useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Redirect, useLocation } from 'react-router-dom';
import { AdbEventLogger, CacheRoute, CacheSwitch, Connect, ErrorDialogProvider, Logger, LoggerContextProvider, ToggleLogger } from './components';
import './index.css';
import logo from './assets/logo.png';
import { DeviceInfo, FileManager, FrameBuffer, Install, Intro, Scrcpy, Shell, TcpIp, SoftwareVersions } from './routes';

const appTheme: PartialTheme = {
    palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#4a6d89',
        themeLighter: '#345773',
        themeLight: '#1f4059',
        themeTertiary: '#0c2437',
        themeSecondary: '#091e2f',
        themeDarkAlt: '#051725',
        themeDark: '#020d15',
        themeDarker: '#00060b',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#b8b8b8',
        neutralSecondary: '#a1a1a1',
        neutralPrimaryAlt: '#8a8a8a',
        neutralPrimary: '#2e2e2e',
        neutralDark: '#5c5c5c',
        black: '#454545',
        white: '#ffffff',
      }
  };

initializeIcons();

const classNames = mergeStyleSets({
    'app-container' : {
        height: '100%'
    },
    'title-container': {
        borderBottom: '1px solid rgb(243, 242, 241)',
    },
    'logo-container': {
        width: '250px',
        textAlign: 'center'
    },
    'logo': {
        width: '150px',
        textAlign: 'center'
    },
    title: {
        padding: '4px 0',
        fontSize: 20,
        fontWeight: 800,
        textAlign: 'center',
    },
    'left-column': {
        width: 250,
        paddingRight: 8,
        borderRight: '1px solid rgb(243, 242, 241)',
        overflow: 'auto',
    },
    'right-column': {
        borderLeft: '1px solid rgb(243, 242, 241)',
    }
});

interface RouteInfo {
    path: string;

    exact?: boolean;

    name: string;

    children: JSX.Element | null;

    noCache?: boolean;
}

function App(): JSX.Element | null {
    const location = useLocation();

    const [logger] = useState(() => new AdbEventLogger());
    const [device, setDevice] = useState<Adb | undefined>();

    const [leftPanelVisible, setLeftPanelVisible] = useState(() => innerWidth > 650);
    const toggleLeftPanel = useCallback(() => {
        setLeftPanelVisible(value => !value);
    }, []);


    const routes = useMemo((): RouteInfo[] => [
        {
            path: '/',
            exact: true,
            name: 'Introduction',
            children: (
                <Intro />
            )
        },
        // {
        //     path: '/device-info',
        //     name: 'Device Info',
        //     children: (
        //         <DeviceInfo device={device} />
        //     )
        // },
        // {
        //     path: '/adb-over-wifi',
        //     name: 'ADB over WiFi',
        //     children: (
        //         <TcpIp device={device} />
        //     )
        // },
        {
            path: '/shell',
            name: 'Interactive Shell',
            children: (
                <Shell device={device} />
            ),
        },
        {
            path: '/file-manager',
            name: 'File Manager',
            children: (
                <FileManager device={device} />
            ),
        },
        {
            path: '/install',
            name: 'Install VB',
            children: (
                <Install device={device} />
            ),
        },
        // {
        //     path: '/versions',
        //     name: 'Software Versions',
        //     children: (
        //         <SoftwareVersions device={device} />
        //     ),
        // },
        // {
        //     path: '/framebuffer',
        //     name: 'Screen Capture',
        //     children: (
        //         <FrameBuffer device={device} />
        //     ),
        // },
        {
            path: '/scrcpy',
            name: 'Remote Screen',
            noCache: true,
            children: (
                <Scrcpy device={device} />
            ),
        },
    ], [device]);

    return (
        <LoggerContextProvider>
            <ThemeProvider theme={appTheme}>
                <Stack verticalFill>
                    <Stack className={classNames['title-container']} horizontal verticalAlign="center">
                        {/* <IconButton
                            checked={leftPanelVisible}
                            title="Toggle Menu"
                            iconProps={{ iconName: 'GlobalNavButton' }}
                            onClick={toggleLeftPanel}
                        /> */}

                        <StackItem className={classNames['logo-container']}> 
                            <img src={logo} className={classNames['logo']} />
                        </StackItem>

                        <StackItem grow>
                            <div className={classNames.title}>VisionBuddy Software Installer</div>
                        </StackItem>

                        <ToggleLogger />
                    </Stack>

                    <Stack grow horizontal verticalFill disableShrink styles={{ root: { minHeight: 0, overflow: 'hidden', lineHeight: '1.5' } }}>
                        <StackItem className={mergeStyles(classNames['left-column'], !leftPanelVisible && { display: 'none' })}>
                            <Connect
                                device={device}
                                logger={logger.logger}
                                onDeviceChange={setDevice}
                            />

                            <Nav
                                styles={{ root: {} }}
                                groups={[{
                                    links: routes.map(route => ({
                                        key: route.path,
                                        name: route.name,
                                        url: `#${route.path}`,
                                    })),
                                }]}
                                selectedKey={location.pathname}
                            />
                        </StackItem>

                        <StackItem grow styles={{ root: { width: 0 } }}>
                            <CacheSwitch>
                                {routes.map<React.ReactElement>(route => (
                                    <CacheRoute
                                        exact={route.exact}
                                        path={route.path}
                                        noCache={route.noCache}>
                                        {route.children}
                                    </CacheRoute>
                                ))}

                                <Redirect to="/" />
                            </CacheSwitch>
                        </StackItem>

                        <Logger className={classNames['right-column']} logger={logger} />
                    </Stack>
                </Stack>
            </ThemeProvider>
        </LoggerContextProvider>
    );
}

ReactDOM.render(
    <HashRouter>
        <ErrorDialogProvider>
            <App />
        </ErrorDialogProvider>
    </HashRouter>,
    document.getElementById('container')
);
